<script>
  // import { selectedBank } from '../../stores/selected-bank.store';
  export let selectedBank;
  import test from './banks/test/test.svelte';
  import testFailed from './banks/test/testFailed.svelte';

  const bankComponents = {
    test,
    testFailed,
  };
</script>

<svelte:component this={bankComponents[selectedBank.name]} on:back />
