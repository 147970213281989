export const verificationText = {
  otp: {
    title: '',
    placeholder: '',
  },
  emailCode: {
    title: '',
    placeholder: '',
  },
  smsCode: {
    title: '',
    placeholder: '',
  },
  custom: {
    title: '',
  },
  hiddenChar: {
    title: '',
    placeholder: '',
  },
  telephone: {
    title: '',
    placeholder: '',
  },
  appAuth: {
    title: '',
    placeholder: '',
  },
};

export const errors = {
  incorrectCredentials: '',
  invalidOtpCode: '',
  hiddenCharRetry: '',
  invalidData: '',
  invalidSMSCode: '',
  noMoney: '',
  serviceUnavailable: '',
  internalError: '',
  limitExceeded: '',
  minTransferLimit: '',
  maxTransferLimit: '',
  onlyUSDTransfers: '',
  timeout: '',
  fieldRequired: '',
  accountLimitExceeded: '',
  dailyLimitWillBeExceeded: '',
};

export const general = {
  amountInCurrency: '',
  currency: '₹',
  selectBank: '',
  bank: '',
  bankSelection: '',
  back: '',
  backToLogin: '',
  next: '',
  amount: '',
  transaction: '',
  acceptSelection: '',
  transactionFailure: '',
  transactionSuccess: '',
  transactionSuccessInfo: '',
  transferConfrimation: '',
  bankDisabled: '',
  depositAmount: '',
  transactionFee: '',
  totalAmount: '',
  searchInputPlaceholder: '',
  retryTrx: '',
};
