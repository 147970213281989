<script>
  import { general as generalStrings } from '@aph-locale/resources/strings.js';

  import BankLogo from '../components/BankLogo.svelte';
  import LabeledText from '../components/LabeledText.svelte';
  import isUnderMaintenancePeriod from '../utils/dateUtils';

  export let bank;
  export let preselected = false;

  $: disabled = isUnderMaintenancePeriod(bank);
</script>

<div on:click class="container" class:disabled>
  <div class="inner-container">
    <input type="radio" checked={preselected} {disabled} />
    <LabeledText label={bank.nameEnglish} text={bank.nameJapanese} />
    <div class="logo-container">
      <BankLogo bankName={bank.name} />
    </div>
  </div>
  {#if disabled}
    <p class="message">{generalStrings.bankDisabled}</p>
  {/if}
</div>

<style>
  .container {
    padding: 0.5rem 2rem 0.5rem 1rem;
    border-top: 1px solid var(--gray-interface);
    border-bottom: 1px solid var(--gray-interface);
  }

  .inner-container {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .disabled {
    background-color: var(--gray-light);
    cursor: not-allowed;
  }

  @media (max-width: 550px) {
    .container {
      padding: 0.5rem;
    }
  }

  .container:not(.disabled):hover,
  .container:not(.disabled):active {
    cursor: pointer;
    background-color: var(--gray-light);
  }

  .logo-container {
    display: flex;
    justify-content: flex-end;
  }

  .message {
    margin-top: 10px;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 300;
    color: var(--red-dark);
    text-align: center;
  }
</style>
