import { writable } from 'svelte/store';
import { errors } from '@aph-locale/resources/errors.js';

export { updateError, setError, generalError, localError, errors, resetAllErrors };

const generalError = writable('');

const localError = writable({
  login: '',
  verification: '',
});

function updateError(state) {
  if (Object.keys(errors).includes(state)) {
    setError(state);
  } else {
    resetError();
  }
}

function setError(state) {
  const error = getError(state);
  if (error.type === 'general') {
    generalError.update(() => error.message);
  } else {
    localError.update((previous) => ({
      ...previous,
      [error.type]: error.message,
    }));
  }
}

function getError(state) {
  if (typeof state === 'string' && Object.keys(errors).includes(state)) {
    return errors[state];
  }
  return errors.Unknown;
}

function resetAllErrors() {
  resetError();
  resetGlobalError();
}

function resetError() {
  localError.update(() => ({
    login: '',
    verification: '',
  }));
}

function resetGlobalError() {
  generalError.update(() => '');
}
