const apiUrl = ''; // TODO update after sls deployment
const banksLogosUrl = '';
const testMerchant = '';
const widgetUrl = '';

module.exports = {
  apiUrl,
  banksLogosUrl,
  testMerchant,
  widgetUrl,
};
